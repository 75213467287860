<template>
  <div class="rep-example">
    <h5>
      <strong>Representative Example</strong>
    </h5>
    <p>
      If you borrow £30,000 over 96 months at a rate of 7.208% fixed for 5 years, you will pay 60 instalments of £465.63 per
      month followed by 36 months at a rate of 8.357% of £473.58 and a total amount payable of £45,081.68. This includes a broker
      fee of £3,300, a lender fee of £595 and the net loan interest of £11,186.68. The overall cost for comparison is 11.4% APRC Variable.
    </p>
    <p class="text-danger">
      Think carefully before securing other debts against your home. Your home may be repossessed if you do not keep up
      repayments
      on your mortgage.
    </p>
  </div>
</template>

<script>
export default {
  name: "RepExample"
}
</script>

<style scoped>
.rep-example {
  font-size: 0.75rem;
}
</style>
